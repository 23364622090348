import React from 'react';
import Modal from 'react-modal';
import Container from '../../../components/UI/Container';

Modal.setAppElement('#___gatsby')
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height: '30rem',
        overflow: 'scroll',
        border: 'none',
        width: '50%',
        height: '50%',
        background: 'whitesmoke',
    }
};

const LoginModal = prop => {
    var subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    // function openModal(data) {
    //     console.log("details",data)
    //     setIsOpen(true);
    // }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (

         <div id='Login_modal'>        
        <Container>
        <Modal
            isOpen={prop.modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >

            
            {/* <h2 ref={_subtitle => (subtitle = _subtitle)}>Hello</h2> */}
        
        </Modal>
        </Container>
         </div>
    );
}
export default LoginModal;